import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@pcweb/controls';

const useStyles = makeStyles({
  root: {},
});

export default function Icon(props) {
  const classes = useStyles();
  return (
    <i {...props} className={clsx(classes.root, 'material-icons-4com', props.className)} role="img">
      {props.children}
    </i>
  );
}
