import { makeStyles, useTheme, Button as MuiButton } from '@pcweb/controls';
import React, { useState, useRef } from 'react';
import { Avatar, AvatarBadge, Popout, alpha } from '@pcweb/controls';
import { useMultichannelSdk, useEvent } from '@pcweb/hooks';

const useStyles = makeStyles(theme => ({
  buttonStyle: {
    float           : 'left',
    cursor          : 'pointer',
    padding         : 0,
    borderRadius    : '50%',
    minWidth        : 0,
    height          : 'auto',
    position        : 'relative',
    marginRight     : theme.dimensions.headerSpacing,
    transition      : `${theme.transitions.create('all')} !important`,
    '-ms-transition': `${theme.transitions.create('all')} !important`,
    boxShadow       : theme.dimensions.shadowButton,
    opacity         : props => (props.disabled === true ? '.6' : '1'),
    backgroundColor : props => {
      if (props.popoverOpened === true) return theme.palette.common.white;
      else if (props.bright === true && props.active === true) return theme.palette.common.white;
      return alpha(theme.palette.neutral[100], 0.2);
    },
    color: props => {
      return props.popoverOpened === true || props.active === true || props.dark === true
        ? theme.palette.blue.dark
        : theme.palette.common.white;
    },
    '&:hover': {
      backgroundColor: props =>
        props.bright === true
          ? theme.palette.blue[900]
          : alpha(theme.palette.neutral[100], 0.5),
      color: props =>
        props.bright === true ? theme.palette.common.white : theme.palette.blue.dark,
    },
    [theme.breakpoints.down('xl')]: {
      '& + &': {
        marginRight: 0,
      },
    },
    '& > span > div': {
      padding                       : '5px',
      textAlign                     : 'center',
      cursor                        : 'pointer',
      display                       : 'flex',
      alignItems                    : 'center',
      flexDirection                 : 'column',
      justifyContent                : 'center',
      [theme.breakpoints.down('xs')]: {
        minWidth: 30,
        height  : 30,
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 35,
        height  : 35,
      },
      [theme.breakpoints.up('md')]: {
        minWidth: 35,
        height  : 35,
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 40,
        height  : 40,
      },
      [theme.breakpoints.up('xl')]: {
        minWidth: 50,
        height  : 50,
      },
      [theme.breakpoints.up('xxl')]: {
        minWidth: 60,
        height  : 60,
      },
    },
  },
  labelStyle: {
    font                          : `${theme.typography.fontWeightMedium} ${theme.typography.size.xs} Din`,
    lineHeight                    : 1,
    display                       : 'block',
    marginTop                     : '0.2rem',
    whiteSpace                    : 'nowrap',
    letterSpacing                 : '1px',
    userSelect                    : 'none',
    transition                    : theme.transitions.create('background-color'),
    textTransform                 : 'uppercase',
    textShadow                    : theme.dimensions.textShadow,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.size.m,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.typography.size.l,
    },
  },
  container: {
    '& span > span > div': {
      border: `2px solid ${theme.palette.blue.dark}`,
    },
  },
  iconStyle: {
    userSelect  : 'none',
    borderRadius: '50%',
    padding     : '2px',
    border      : `2px solid ${theme.palette.common.white}`,
    textShadow  : theme.dimensions.textShadow,
    '&.grey'    : {
      backgroundColor: theme.palette.text.disabled,
    },
    '&.green': {
      backgroundColor: theme.palette.status.free.main,
    },
    '&.red': {
      backgroundColor: theme.palette.status.busy.main,
    },
    '&.blue': {
      backgroundColor: theme.palette.status.break.main,
    },
    '&.orange': {
      backgroundColor: theme.palette.status.reserved.main,
    },
    '&.yellow': {
      backgroundColor: theme.palette.status.refinish.main,
    },
    '& > i': {
      color: props =>  props.shortStatusName ?
        theme.palette.status[props.shortStatusName].contrastText.main : theme.palette.secondary.main,
      fontSize: '12px',
    },
  },
  avatar: {
    '& > img': {
      height: '140%',
      width : '140%'
    }
  }
}));

const Button = React.forwardRef((props, ref) => {
  const classes = useStyles(props);

  const passProps = { ...props };
  delete passProps.bright;
  delete passProps.popoverOpened;
  delete passProps.chevron;
  delete passProps.drawerPaperProps;

  return (
    <MuiButton ref={ref} className={classes.buttonStyle} {...passProps}>
      {props.children}
    </MuiButton>
  );
});

/**
 *
 * MainMenuButton
 *
 * Callbacks
 * onClick()                    => triggered, if the button pushed / returns {event}
 * onStateChanged()             => returns the current open State {boolean}
 *
 * Key Events
 * onKeyEscape()                => triggered if Escape was pushed : default behaviour: close popover
 * onKeyEnter()                 => triggered if Enter was pushed
 * onKeyArrow()                 => returns {'up','down','left' or 'right'}
 * onKeyDown()                  => returns the event
 *
 * Properties
 * open {boolean}               => enable/disable the button from outside
 * noPadding {boolean}
 * bright {boolean}
 * disabled {boolean}
 * label {string}
 * anchorElement
 * anchorOrigin {object}        => default { vertical: 'bottom', horizontal: 'left' }
 * transformOrigin {object}     => default { vertical: 'top', horizontal: 'left' }
 * popoverClass {string}
 * data-element {string}
 */

const MainMenuButton = React.memo(props => {
  const sdk = useMultichannelSdk();
  const { translate } = sdk.language;
  const [state, setState] = useState({ open: false });
  const [avatar, setAvatar] = useState(sdk.currentUser?.avatar);
  const _buttonRef = useRef(null);
  const classes = useStyles(props);
  const theme = useTheme();

  function _isOpen() {
    return props.open != 'undefined' ? props.open : state.open;
  }

  function _open() {
    if (props.onStateChanged) props.onStateChanged(true);
    setState({ ...state, open: true });
  }

  function _close() {
    if (props.onStateChanged) props.onStateChanged(false);
    setState({ ...state, open: false });
  }

  function _toggle() {
    props.children && _isOpen() === false ? _open() : _close();
  }

  function _onKeyDown(event) {
    switch (event.key) {
    case 'Escape':
      props.onKeyEscape ? props.onKeyEscape() : _close();
      break;
    case 'Enter':
      if (props.onKeyEnter) {
        props.onKeyEnter();
      }
      break;
    case 'ArrowUp':
      if (props.onKeyArrow) {
        _onKeyArrow('up');
      }
      break;
    case 'ArrowDown':
      if (props.onKeyArrow) {
        _onKeyArrow('down');
      }
      break;
    case 'ArrowLeft':
      if (props.onKeyArrow) {
        _onKeyArrow('left');
      }
      break;
    case 'ArrowRight':
      if (props.onKeyArrow) {
        _onKeyArrow('right');
      }
      break;
    default:
    }

    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  }

  function _onKeyArrow(dir) {
    if (props.onKeyArrow) {
      props.onKeyArrow(dir);
    }
  }

  function _onClick(e) {
    props.onClick ? props.onClick(e) : _toggle();
  }

  useEvent(['sdk.me.avatar.new'], avatar => {
    setAvatar(avatar);
  });

  return (
    <div className={classes.container}>
      <AvatarBadge classes={{ iconStyle: classes.iconStyle }} statusName={props.statusName}>
        <Button
          data-element={props['data-element']}
          bright={true}
          title={props.label}
          onClick={_onClick}
          popoverOpened={_isOpen()}
          ref={_buttonRef}
          disabled={props.disabled}
          aria-label={translate('Hauptmenü')}
        >
          {avatar ? <Avatar className={classes.avatar} src={avatar} /> :
            <div>{props.label && <span className={classes.labelStyle}>{props.label}</span>}</div>}
        </Button>
      </AvatarBadge>


      {props.children && _isOpen() && (
        <Popout
          classes={{ paper: props.popoverClass }}
          anchorEl={props.anchorElement ? props.anchorElement : _buttonRef.current}
          onClose={_close}
          onKeyDown={_onKeyDown}
          popoverPaperProps={{
            style: {
              minWidth    : '250px',
              marginTop   : '5px',
              padding     : props.noPadding ? 0 : '5px',
              borderRadius: '4px',
              overflow    : 'hidden',
              boxShadow   : theme.dimensions.shadowDialog
            },
          }}
          drawerPaperProps={{
            style: {
              padding: props.noPadding ? 0 : '1em',
              bottom : 0,
            },
          }}
          anchorOrigin={
            props.anchorOrigin ? props.anchorOrigin : { vertical: 'bottom', horizontal: 'left' }
          }
          transformOrigin={
            props.transformOrigin ? props.transformOrigin : { vertical: 'top', horizontal: 'left' }
          }
          open={_isOpen()}
        >
          {props.children}
        </Popout>
      )}
    </div>
  );
});

export default MainMenuButton;
