import React, { forwardRef } from 'react';
import { makeStyles, TextField } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  input: {
    borderRadius   : theme.dimensions.borderRadiusInput,
    backgroundColor: theme.palette.background.paper,
  },
  inputMarginDense: {
    paddingTop   : '8.5px',
    paddingBottom: '8.5px',
  },
  notchedOutline: {
    borderColor: theme.palette.secondary.main,
    transition : theme.transitions.create('border-color'),
    '&:hover'  : {
      borderColor: theme.palette.primary.main,
    }
  },
  focused: {
    '& $notchedOutline': {
      borderWidth: '1px !important',
      borderColor: theme.palette.primary.main + '!important',
    },
  },
  disabled   : {},
  error      : {},
  marginDense: {
    transform: 'translate(14px, 10px) scale(1) !important',
  },
  shrink: {
    transform: 'translate(14px, -6px) scale(0.75) !important',
  },
  labelDisabled: {
    color: `${theme.palette.grey[800]} !important`
  }
}));

const TextInputField = (props, ref) => {
  const classes = useStyles(props);
  return (
    <TextField
      {...props}
      type="text"
      onChange={props.onChange}
      label={props.name}
      placeholder={props.placeholder}
      color="secondary"
      classes={{ ...props.classes }}
      margin="dense"
      variant="outlined"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      ref={ref}
      InputLabelProps={{
        classes: {
          shrink     : classes.shrink,
          marginDense: classes.marginDense,
          disabled   : classes.labelDisabled
        },
      }}
      InputProps={{
        classes: {
          root            : classes.root,
          input           : classes.input,
          inputMarginDense: classes.inputMarginDense,
          notchedOutline  : classes.notchedOutline,
          focused         : classes.focused,
          disabled        : classes.disabled,
          error           : classes.error,
        },
      }}
    ></TextField>
  );
};

export default forwardRef(TextInputField);
