import React, { useContext, useEffect, useState, useRef } from 'react';
import { useLocation as routerUseLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { SdkContext } from './SdkContext';
import { Composition } from '../../gui/src/gui/lib/Composition';
import { useTheme } from '@pcweb/controls';
import { useObserver as mobXUseObserver } from 'mobx-react';
import Events from '@core/events';

import MultichannelSdk from '@multichannel/sdk';


export { SdkContext } from './SdkContext';

export const useObserver = mobXUseObserver;
/**
 * useComposition lets components easily create and use compositions for their body.
 * To use this hook a default composition should be provided by listing n ComponentType in an array.
 * The composition will render its components in the order they have been defined.
 *
 * The new composition will automatically be pushed to gui.compositions under the given name.
 *
 */
export const useComposition = (name, defaultComposition = []) => {
  const gui = useGui();
  const compositionConfig = gui.config ? gui.config.compositions : null;

  //eslint-disable-next-line
  const [currentComposition, setCurrentComposition] = useState([]);

  // Note that we are using a ref here to store the composition object. By doing so, we can utilize Reacts capability
  // to store a mutable object throughout the surrounding components life cycle.
  const composition = useRef(null);

  if (!composition.current) {
    let useCompo = [];

    // If the gui config contains a config for the composition, reorder and remove compositions!
    if (compositionConfig && compositionConfig[name]) {
      compositionConfig[name].forEach(compName => {
        const findCompostion = defaultComposition.find(comp => comp.id === compName);
        if (findCompostion) {
          useCompo.push(findCompostion);
        }
      });
    }

    // if no content was found, set the default Composition
    if (!useCompo.length) {
      useCompo = [...defaultComposition];
    }
    // Initiate the composition on the first invoking of this hook. Notice how we only do this if and when the ref
    // is not yet initialized (.current is undefined). This way we can ensure to not create too many new instances
    // of the same composition on multiple life cycle events of React.
    composition.current = new Composition(useCompo, compositionState =>
      setCurrentComposition(compositionState),
    );

    // Push the composition to the package so it can be discovered.
    gui.compositions.set(name, composition.current);
  }

  return composition.current;
};

/**
 * A simple alias for useContext to make usage of the SDK more explicit.
 *
 */
/**
 * @type {MultichannelSdk}
 */
export const useMultichannelSdk = (): MultichannelSdk => {
  // @ts-ignore
  return useContext(SdkContext);
};

/**
 * A shorthand for using useMultichannelSdk().plugins.get('pcweb/gui) to save you from typing redundant code.
 *
 */
export const useGui = () => useMultichannelSdk().plugins.get('pcweb/gui');

export const useTranslate = () => useMultichannelSdk().language.translate;

export const useLocationCallback = (route, callback, deps = []) => {
  const gui = useGui();
  useEffect(() => {
    gui.router.addCallback(route,callback);
    return () => gui.router.removeCallback(route);
  }, deps);
};
/**
 * useEvent allows consumers to easily use events of the Multichannel SDK without worrying about cleaning up the
 * subscription.
 *
 */
export const useEvent = (eventId, handler, deps = []) => {
  useEffect(() => {
    const subscriptions = Events.on(eventId, handler);
    return () => Events.off(subscriptions);
  }, deps);
};

export const useEventOnce = (eventId, handler, deps = []) => {
  useEffect(() => {
    const subscriptions = Events.once(eventId, handler);
    return () => Events.off(subscriptions);
  }, deps);
};

export const useStream = eventId => {
  return Events.stream(eventId);
};

export const useLocation = routerUseLocation;
export const useStyled = callback => {
  const Element = React.useRef(null);
  const theme = useTheme();
  if (!Element.current) {
    Element.current = callback({ styled, css, theme });
  }

  return Element.current;
};

/**
 * Takes a component factory (compatible to gui.wrapComponent) and returns the resulting react component.
 *
 */
export const useWrapped = ComponentFactory => {
  const gui = useGui();
  const component = React.useRef(null);

  if (!component.current) {
    component.current = gui.wrapComponent(ComponentFactory);
  }

  return component.current;
};

/**
 * A really common need is to get the current size of the browser window.
 * This hook returns an object containing the window's width and height.
 */
export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width : isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const useFormattedDuration = duration => {
  let result = '';
  const pad = number => (number < 10 ? '0' : '') + number;

  if (duration.days() > 0) {
    result += pad(duration.days()) + ':';
  }

  if (duration.hours() > 0) {
    result += pad(duration.hours()) + ':';
  }

  // Always display minutes and seconds.
  result += pad(duration.minutes()) + ':' + pad(duration.seconds());

  return result;
};