import React from 'react';
import { makeStyles } from '@pcweb/controls';
import { Icon } from '@pcweb/controls';
import QuestionTextBox from './QuestionTextBox';
import QuestionLinks from './QuestionLinks';
import QuestionDropDown from './QuestionDropDown';
import QuestionCheckBox from './QuestionCheckBox';
import QuestionRadioButton from './QuestionRadioButton';
import { observer } from 'mobx-react';

const useStyles = makeStyles(
  (muiTheme) => ({
    bubble: {
      position       : 'relative',
      backgroundColor: muiTheme.palette.neutral.light,
      padding        : '14px 7px 4px 7px',
      marginBottom   : 14,
      borderRadius   : muiTheme.dimensions.borderRadiusPanel,
      '& h3'         : {
        fontSize    : muiTheme.typography.size.l,
        marginTop   : 5,
        marginBottom: 5,
      },
      '& > fieldset': {
        display: 'block',
      },
    },
    bubbleWithoutIcon: {
      position       : 'relative',
      backgroundColor: muiTheme.palette.neutral.light,
      padding        : '4px 7px',
      marginBottom   : 14,
      borderRadius   : muiTheme.dimensions.borderRadiusPanel,
      '& h3'         : {
        fontSize    : muiTheme.typography.size.l,
        marginTop   : 5,
        marginBottom: 5,
      },
      '& > fieldset': {
        display: 'block',
      },
    },
    badge: {
      position       : 'absolute',
      top            : '-10px',
      right          : 7,
      display        : 'flex',
      alignItems     : 'center',
      justifyContent : 'center',
      backgroundColor: muiTheme.palette.common.white,
      boxShadow      : 'rgba(190, 201, 215, 0.5) 0px 1px 1px 1px',
      borderRadius   : '50%',
      height         : 20,
      width          : 20,
    },
    badgeRequired: {
      position       : 'absolute',
      top            : '-10px',
      left           : 7,
      display        : 'flex',
      alignItems     : 'center',
      justifyContent : 'center',
      backgroundColor: muiTheme.palette.common.white,
      boxShadow      : 'rgba(190, 201, 215, 0.5) 0px 1px 1px 1px',
      borderRadius   : '50%',
      height         : 20,
      width          : 20,
    },
    red: {
      color: muiTheme.palette.utility.red.main,
    },
    green: {
      color: muiTheme.palette.utility.green.main,
    },
  }),
  { name: 'ConservationGuideQuestion' }
);

export const Question = observer((props) => {
  const classes = useStyles();
  const question = props.question;
  const showValidation =
    question.isMandatory ||
    (question.questionType === 1 &&
      question.value &&
      question.value.length > 0);

  const options = {
    callflow: props.callflow,
    question,
    disabled: !props.callflow.isRunning || props.callflow._disabled,
  };

  return (
    <div
      className={
        question.isMandatory ? classes.bubble : classes.bubbleWithoutIcon
      }
      data-element={'conversation-guide.question-' + question.id}
    >
      {showValidation && (
        <div className={classes.badge}>
          {!question.isEvaluated && <Icon className={classes.red}>clear</Icon>}
          {question.isEvaluated && question.hasError && <Icon className={classes.red}>clear</Icon>}
          {question.isEvaluated && !question.hasError && (
            <Icon className={classes.green}>check</Icon>
          )}
        </div>
      )}

      {question.isMandatory && (
        <div className={classes.badgeRequired}>
          <Icon className={classes.red}>error</Icon>
        </div>
      )}
      <h3>{props.question.question}</h3>
      {question.questionType === 1 && <QuestionTextBox {...options} />}
      {question.questionType === 2 && <QuestionDropDown {...options} />}
      {question.questionType === 3 && <QuestionCheckBox {...options} />}
      {question.questionType === 4 && <QuestionRadioButton {...options} />}
      {question.questionType === 5 && <QuestionLinks {...options} />}
    </div>
  );
});

export default Question;
