import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, TreeView, TreeItem } from '@pcweb/controls';
import { Icon } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.dimensions.borderRadiusInput,
    color       : theme.palette.text.primary,
    '& i'       : {
      color: theme.palette.secondary.main,
    },
    '&:focus > $content $label': {
      backgroundColor: theme.palette.action.hover,
    },
    '&$selected > $content $label': {
      backgroundColor: theme.palette.action.selected,
    },
    '&$selected > $content $label:hover, &$selected:focus > $content $label': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  content: {
    color       : theme.palette.text.primary,
    borderRadius: theme.dimensions.borderRadiusInput,
    paddingRight: theme.spacing(1),
  },
  group: {
    marginLeft: 0,
  },
  iconContainer: {
    minWidth: 16,
    margin  : 0,
  },
  label: {
    transition     : theme.transitions.create('all'),
    fontWeight     : theme.typography.fontWeightMedium,
    borderRadius   : theme.dimensions.borderRadiusInput,
    backgroundColor: props =>
      props.itemSelected ? `${theme.palette.action.selected} !important` : 'transparent',
  },
  labelRoot: {
    display   : 'flex',
    alignItems: 'center',
    padding   : theme.spacing(0.5, 1, 0.5, 0.5),
    '& > i'   : {
      paddingRight: theme.spacing(1),
      minWidth    : 22,
    },
  },
  labelText: {
    flexGrow    : 1,
    whiteSpace  : 'nowrap',
    overflow    : 'hidden',
    textOverflow: 'ellipsis',
    width       : 100,
    '& > div'   : {
      whiteSpace  : 'nowrap',
      overflow    : 'hidden',
      textOverflow: 'ellipsis',
      width       : 100,
    },
  },
  expanded: {},
  selected: {},
}));

export const TreeItemComp = props => {
  const classes = useStyles(props);
  const {
    labelText,
    labelIcon,
    labelInfo,
    onItemSelected,
    isItemSelected,
    itemSelected,
    color,
    bgColor,
    iconColor,
    title,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Icon style={{ fontSize: '14px' }} aria-label="Label Icon">{labelIcon}</Icon>
          <div className={classes.labelText}>{labelText}</div>
          <div>{labelInfo}</div>
        </div>
      }
      title={props.labelText}
      classes={{
        root         : classes.root,
        content      : classes.content,
        expanded     : classes.expanded,
        group        : classes.group,
        iconContainer: clsx(classes.iconContainer, 'iconContainer'),
        label        : classes.label,
        selected     : classes.selected,
      }}
      {...other}
    />
  );
};

TreeItemComp.propTypes = {
  bgColor  : PropTypes.string,
  color    : PropTypes.string,
  iconColor: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export const TreeViewComp = props => {
  return <TreeView {...props}>{props.children}</TreeView>;
};
