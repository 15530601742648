import React from 'react';
import { makeStyles } from '@pcweb/controls';
import { observer } from 'mobx-react';
import ConversationForm from './ConversationForm';
import { Icon, IconButton, Stepper, Step, StepLabel, StepContent } from '@pcweb/controls';
import { ErrorBoundary, EmptyModule } from '../../Package';

const useStyles = makeStyles(muiTheme => ({
  container: {
    display                               : 'flex',
    flexDirection                         : 'row',
    width                                 : '100%',
    height                                : '100%',
    overflowY                             : 'hidden',
    position                              : 'relative',
    '@media screen and (max-width: 700px)': {
      minHeight: 341,
    },
  },
  navigation: {
    borderRight           : `1px solid ${muiTheme.palette.neutral[600]}`,
    borderBottomLeftRadius: muiTheme.dimensions.borderRadiusPanel,
    backgroundColor       : muiTheme.palette.neutral.A100,
    overflowY             : 'auto',
    overflowX             : 'hidden',
    padding               : '7px 7px 7px 7px',
    minWidth              : 160,
  },
  guideDescription: {
    fontSize    : muiTheme.typography.size.m,
    color       : muiTheme.palette.text.primary,
    marginBottom: 20,
    lineHeight  : '1.3',
  },
  refreshContainer: {
    position: 'absolute',
    right   : 0,
    top     : 0,
    padding : 2,
  },
  emptyModule: {
    display       : 'flex',
    justifyContent: 'center',
    height        : '100%',
    width         : '100%',
    alignItems    : 'center',
    flexDirection : 'column',
  },
}),
{name: 'ConservationGuideBody'}
);

export const ComponentConversationGuide = observer(props => {
  const callFlow = props.callflow;
  const classes = useStyles();

  if (!callFlow || !callFlow.hasCallGuidesLoaded || !callFlow.activeCallGuide) {
    return <ErrorBoundary>
      <EmptyModule
        icon="inbox"
        message={'Aktuell_kein_Kontakt'}
        className={classes.emptyModule}
      />
    </ErrorBoundary>;
  }

  const activePage = callFlow.activeCallGuide.activePage;
  const activePageIsValid = activePage && activePage.isEvaluated && !activePage.hasError;
  const isLastPage = activePage && activePage.nextPageId === 0 && activePage.questionNextPageId === 0;

  const disableNext = isLastPage ||
    (callFlow.isRunning && !activePageIsValid);

  const steps = callFlow.isRunning
    ? callFlow.activeCallGuide.activePages
    : callFlow.activeCallGuide.pages;

  const handleNext = () => {
    callFlow.activeCallGuide.evaluateNext();
  };

  const handleBack = () => {
    callFlow.activeCallGuide.evaluateBack();
  };

  const handleReset = () => {
    callFlow.activeCallGuide.reset();
    if (props.handleReset) {
      props.handleReset();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.navigation}>
        <div className={classes.guideDescription}>
          {callFlow.activeCallGuide.activePage
            ? callFlow.activeCallGuide.activePage.description
            : ''}
        </div>
        {steps.length > 0 && (
          <Stepper activeStep={callFlow.activeCallGuide.activeStep} orientation="vertical">
            {steps.map(page => {
              return (
                <Step key={page.id}>
                  <StepLabel>{page.name}</StepLabel>
                  <StepContent>
                    <div>
                      <IconButton
                        size="small"
                        disabled={callFlow.activeCallGuide.activeStep === 0}
                        onClick={handleBack}
                      >
                        <Icon>arrow_back</Icon>
                      </IconButton>
                      <IconButton size="small" onClick={handleNext} disabled={disableNext}>
                        <Icon>arrow_forward</Icon>
                      </IconButton>
                    </div>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        )}
        {callFlow.isRunning && (
          <div className={classes.resetContainer}>
            <IconButton disabled={!callFlow.canReset} size="small" onClick={handleReset}>
              <Icon>refresh</Icon>
            </IconButton>
          </div>
        )}
      </div>
      <ConversationForm callflow={callFlow} handleSave={props.handleSave} />
    </div>
  );
});

export default ComponentConversationGuide;
