import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@pcweb/controls';
import { makeStyles } from '@pcweb/controls';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    '& > label': {
      marginLeft: '-5px',
      '& > span': {
        padding: theme.spacing(0.5),
      },
    },
  },
}),
{name: 'ConservationGuideQuestionRadioButton'}
);

export const QuestionRadioButton = observer(props => {
  const question = props.question;
  const callFlow = props.callflow;
  const classes = useStyles();
  const activePage = callFlow.activeCallGuide.activePage;

  return (
    <FormControl component="fieldset" disabled={props.disabled}>
      <RadioGroup
        aria-label={question.question}
        name={'' + question.id}
        className={classes.formGroup}
      >
        {question.options.map(option => {
          return (
            <FormControlLabel
              key={option.id}
              value={option.value}
              control={<Radio color="primary"/>}
              label={option.label}
              checked={question.selected && parseInt(question.selected.id, 10) === parseInt(option.id, 10)}
              onChange={e => {
                if (e.target.value) {
                  runInAction(() => question.selected = question.options.find(option => option.value === e.target.value));
                }
                activePage.evaluate();
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
});

export default QuestionRadioButton;

