import React from 'react';
import { EmptyModule as PCEmptyModule } from '@pcweb/controls';
import { useTranslate } from '@pcweb/hooks';

export function EmptyModule({ message, ...props }) {
  const translate = useTranslate();
  return <PCEmptyModule
    message={translate(message)}
    {...props}
    data-element={props['data-element'] || undefined}
  ></PCEmptyModule>;
}
