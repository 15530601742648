import React from 'react';
import ComponentContainer from './Composition/ComponentContainer';

import { ErrorBoundary } from '../../controls/Package';

/**
 * A CompositionManager enables components to provide a common API to their content.
 *
 */
export class Composition {
  static COMPONENT_TYPE_DOM = 'dom';
  static COMPONENT_TYPE_REACT = 'react';

  constructor(_components = [], _onChange) {
    this._components = _components;
    this._onChange = _onChange;
  }

  /**
   * Returns a list of serializable components.
   *
   */
  get entries() {
    return this._filterNonSerializable(this._components) || [];
  }

  _refs = [];

  add(component, fire = true) {
    this._components.push(component);
    if(fire) this._fireOnChange();
  }

  /**
   * Adds an element after another.
   *
   */
  addAfter(followerId, component) {
    this._components.splice(this._findOrExit(followerId) - 1, 0, component);
    this._fireOnChange();
  }

  /**
   * Adds an element before another.
   *
   */
  addBefore(predecessorId, component) {
    this._components.splice(this._findOrExit(predecessorId), 0, component);
    this._fireOnChange();
  }

  /**
   * Adds an element to the end of the list.
   *
   */
  append(component) {
    this._components.push(component);
    this._fireOnChange();
  }

  /**
   * Count all elements of the list.
   *
   */
  count() {
    return this._components.length || 0;
  }

  /**
   * Returns the body of the given component.
   *
   */
  get(id) {
    return this._components[this._findOrExit(id)];
  }

  has(id) {
    return this._components.findIndex(component => component.id === id) >= 0 ? true : false;
  }

  moveAfter(id, targetId) {
    const key = this._components.findIndex(component => component.id === id);
    const targetKey = this._components.findIndex(component => component.id === targetId);

    if (key >= 0) {
      this._components.splice(targetKey, 0, this._components.splice(key, 1)[0]);
      this._fireOnChange();
    }
  }

  moveBefore(id, targetId) {
    const key = this._components.findIndex(component => component.id === id);
    const targetKey = this._components.findIndex(component => component.id === targetId);

    if (key >= 0) {
      this._components.splice(targetKey - 1, 0, this._components.splice(key, 1)[0]);
      this._fireOnChange();
    }
  }

  moveToEnd(id) {
    const key = this._components.findIndex(component => component.id === id);

    if (key >= 0) {
      this._components.splice(this._components.length - 1, 0, this._components.splice(key, 1)[0]);
      this._fireOnChange();
    }
  }

  moveToStart(id) {
    const key = this._components.findIndex(component => component.id === id);

    if (key >= 0) {
      this._components.splice(0, 0, this._components.splice(key, 1)[0]);
      this._fireOnChange();
    }
  }

  /**
   * Adds an element to the beginning of the list.
   *
   */
  prepend(component) {
    this._components.unshift(component);
    this._fireOnChange();
  }

  /**
   * Removes an element.
   *
   */
  remove(id) {
    this._components.splice(this._findOrExit(id), 1);
    this._fireOnChange();
  }

  render(options = {}) {
    let i = 0;
    let length = this._components.length;
    return this._components.map(component => {
      let componentBody;

      if (component.type === Composition.COMPONENT_TYPE_DOM) {
        componentBody = (
          <ComponentContainer key={component.id} {...options.props} component={component} />
        );
      } else {
        componentBody = component.render(options.props);
        if (componentBody !== null) {
          return (
            <React.Fragment key={component.id}>
              <ErrorBoundary>{componentBody}</ErrorBoundary>
            </React.Fragment>
          );
        }
      }

      if (!componentBody) {
        // Decrement the length of visible components and don't output anything.
        // Note how this won't invoke options.renderComponent as well.
        length--;
        return null;
      }

      i++;

      if (options.renderComponent) {
        return options.renderComponent(componentBody, i, length);
      }

      return componentBody;
    });
  }

  /**
   * Replaces an element with another.
   *
   */
  replace(originalId, callback) {
    const component = callback(this._components[this._findOrExit(originalId)].render);

    component.id = originalId;
    component.isReplaced = true;

    this._components[this._findOrExit(originalId)] = component;
    this._fireOnChange();
  }

  /**
   * Attempts to find the index of a given component in our list. Throws an exception if the component does not exist.
   *
   */
  _findOrExit(id) {
    const index = this._components.findIndex(component => component.id === id);

    if (index < 0) {
      throw new CompositionException(`Component "${id}" could not be found`);
    }

    return index;
  }

  _fireOnChange() {
    if (this._onChange) this._onChange(this._components.map(component => component.id));
  }

  /**
   * This is taken from abstract class Comparable.ts
   * TODO reduce copy-paste by exporting logic
   */
  _filterNonSerializable(components) {
    return components.map(component => {
      const entries = Object.entries(component);
      const filteredEntries = Object.create(null);
      entries.forEach(([key, value]) => {
        if (value === null || value === undefined) return;
        if (typeof value === typeof undefined || typeof value === 'function') return;
        if (key[0] === '_') return;
        try {
          JSON.stringify(key) && JSON.stringify(value);
        } catch (e) {
          return;
        }
        filteredEntries[key] = value;
      });
      return filteredEntries;
    });
  }
}

// tslint:disable-next-line:max-classes-per-file
class CompositionException extends Error {}
