import React from 'react';
import { Checkbox, FormControlLabel, FormControl, FormGroup } from '@pcweb/controls';
import { makeStyles } from '@pcweb/controls';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    '& > label': {
      marginLeft: '-5px',
      '& > span': {
        padding: theme.spacing(0.5),
      },
    },
  },
}),
{name: 'ConservationGuideQuestionCheckbox'}
);

export const QuestionCheckBox = observer(props => {
  const question = props.question;
  const classes = useStyles();
  const callFlow = props.callflow;
  const activePage = callFlow.activeCallGuide.activePage;

  return (
    <FormControl component="fieldset" disabled={props.disabled}>
      <FormGroup className={classes.formGroup}>
        {question.options.map(option => {
          const checked = typeof question.selected[option.id] !== 'undefined';

          return (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  color="primary"
                  value={option.id}
                  name={'' + question.id}
                  checked={checked}
                  onChange={e => {
                    if (e.target.checked) {
                      runInAction(() => question.selected[e.target.value] = question.options.find(
                        opt => opt.id === parseInt(e.target.value, 10),
                      ));
                    } else {
                      runInAction(() => delete question.selected[e.target.value]);
                    }
                    activePage.evaluate();
                  }}
                />
              }
              label={option.label}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
});

export default QuestionCheckBox;
