import React from 'react';
import { Button, makeStyles } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight    : theme.dimensions.inputHeight,
    letterSpacing: theme.dimensions.letterSpacing,
    borderRadius : theme.dimensions.borderRadiusInput,
    transition   : theme.transitions.create('all'),
    '& i'        : {
      transition   : theme.transitions.create('all'),
      marginRight: 5,
      color      : `${theme.palette.secondary.main} !important`
    },
    '&:hover': {
      color          : theme.palette.primary.main,
      borderColor    : theme.palette.primary.main,
      backgroundColor: 'transparent',
      '& i'          : {
        color: `${theme.palette.primary.main} !important`
      },
    },
  },
  disabled: {
    '& i': {
      color: `${theme.palette.text.disabled} !important`
    },
  },
  outlinedSecondary: {
    border: `1px solid ${theme.palette.secondary.main}`
  }
}));

const SecondaryButton = props => {
  const classes = useStyles();
  return (
    <Button classes={{ root: classes.root, disabled: classes.disabled, outlinedSecondary: classes.outlinedSecondary }} variant="outlined" color="secondary" {...props}>
      {props.children}
    </Button>
  );
};

export default SecondaryButton;
