import React from 'react';
import { makeStyles, IconButton } from '@pcweb/controls';
import { Icon } from '@pcweb/controls';

const useStyles = makeStyles(muiTheme => ({
  root: {
    '& .red': {
      color: muiTheme.palette.utility.red.main,
    },
    '& .green': {
      color: muiTheme.palette.utility.green.main,
    },
  },
}));

export default function IconButtonComponent(props) {
  const classes = useStyles();
  return (
    <IconButton
      {...props}
      classes={{
        root: classes.root,
        ...props.classes,
      }}
      color="primary"
    >
      <Icon>{props.children}</Icon>
    </IconButton>
  );
}
