import React from 'react';
import {
  SelectComponentOld as SelectComponent
} from '@pcweb/controls';
import { useTranslate } from '@pcweb/hooks';

const SelectComponentOld = ({ message, ...props }) => {
  const translate = useTranslate();
  return <SelectComponent
    placeholder={translate('Option_wählen')}
    {...props}
  />;
};

export default SelectComponentOld;