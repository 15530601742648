import React from 'react';
import { makeStyles } from '@pcweb/controls';
import { useMultichannelSdk } from '@pcweb/hooks';
import { observer } from 'mobx-react';
import { Icon, SecondaryButton, PrimaryButton, SubTabs, SubTab } from '@pcweb/controls';

import Question from './question/Question';

const useStyles = makeStyles(muiTheme => ({
  stickyContainer: {
    display      : 'flex',
    flexDirection: 'column',
    overflow     : 'hidden',
    height       : '100%',
    width        : '100%',
  },
  stickyMainContainer: {
    flexGrow     : 1,
    height       : '100%',
    overflowY    : 'scroll',
    display      : 'flex',
    flexDirection: 'column',
    alignItems   : 'flex-start',
    padding      : '14px 7px 0px 7px',
    position     : 'relative',
  },
  refreshContainer: {
    position: 'absolute',
    right   : 0,
    top     : 0,
    padding : 2,
  },
  footer: {
    display        : 'flex',
    flexDirection  : 'row',
    justifyContent : 'flex-end',
    alignItems     : 'center',
    width          : '100%',
    height         : 30,
    padding        : 2,
    backgroundColor: muiTheme.palette.neutral.light,
    borderTop      : `1px solid ${muiTheme.palette.neutral[600]}`,
    '& button'     : {
      minHeight     : muiTheme.dimensions.inputHeightSmall,
      height        : muiTheme.dimensions.inputHeightSmall,
      '&:last-child': {
        marginLeft: 5,
      },
    },
  },
}),
{name: 'ConservationGuideForm'}
);

export const ConversationForm = observer(props => {
  const sdk = useMultichannelSdk(),
    callFlow = props.callflow,
    disabled = !callFlow.isRunning || !callFlow.activeCallGuide.activePage || callFlow._disabled;

  const { translate } = sdk.language;
  const classes = useStyles();

  const handleSave = () => {
    if (props.handleSave) props.handleSave();
  };

  const handleReset = () => {
    callFlow.activeCallGuide.activePage.reset();
  };

  return (
    <div className={classes.stickyContainer}>
      <SubTabs variant="fullWidth" value={callFlow.activeCallGuide.id}>
        {Object.entries(callFlow.activeCallGuides).map(data => {
          const [id, guide] = data;
          return (
            <SubTab
              key={id}
              value={id}
              label={guide.name}
              onClick={() => (callFlow.activeCallGuide = callFlow.activeCallGuides[id])}

            />
          );
        })}
      </SubTabs >
      <div className={classes.stickyMainContainer}>
        {callFlow.activeCallGuide.activePage &&
          callFlow.activeCallGuide.activePage.questions.map(question => (
            <Question data-element={`conversation-guide-${question.id}`} key={question.id} question={question} callflow={callFlow} />
          ))}
      </div>
      <div className={classes.footer}>
        <SecondaryButton size="small" disabled={disabled} onClick={() => handleReset()}>
          <Icon data-element="delete-btn-conversation-guide-after-get-call">delete</Icon> {translate('Löschen')}
        </SecondaryButton>
        <PrimaryButton size="small" disabled={!callFlow.isStoreable} onClick={() => handleSave()}>
          <Icon data-element="save-btn-conversation-guide-after-get-call">save</Icon> {translate('Speichern')}
        </PrimaryButton>
      </div>
    </div>
  );
});

export default ConversationForm;

