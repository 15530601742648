import React, { useEffect, useRef } from 'react';

/**
 * A container for third party components that are not necessarily React components but functions. These functions may
 * utilize the proxy element we hand over to them by updating its innerHTML or innerText on their own. They might also
 * return a value once invoked that we should use as content instead.
 *
 */
export const ComponentContainer = props => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      props.component.render(ref.current, props);
      return () => {
        if (props.component.unmount) {
          if (ref.current) props.component.unmount(ref.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    props.component.render(ref.current, props);
  }, [props.dimensions]);

  return <div ref={ref} />;
};

export default ComponentContainer;
