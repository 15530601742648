import React from 'react';
import { makeStyles, useTheme } from '@pcweb/controls';
import styled, { css } from 'styled-components';
import { ContextMenu as OriginalContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { List as MuiList, ListItem, ListSubheader, ListItemText, Portal } from '@pcweb/controls';
import { Icon } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  contextMenuContainer: {
    boxShadow                     : theme.dimensions.shadowDialog,
    borderRadius                  : theme.dimensions.borderRadiusPanel,
    backgroundColor               : theme.palette.background.paper,
    minWidth                      : '160px',
    outline                       : 0,
    opacity                       : 0,
    pointerEvents                 : 'none',
    transition                    : theme.transitions.create('opacity'),
    marginLeft                    : '-5px',
    marginTop                     : '-5px',
    fontSize                      : '10px',
    '&.react-contextmenu--visible': {
      opacity      : '1',
      pointerEvents: 'auto',
      zIndex       : '9999',
    },
  },
  subMenuContainer: {
    boxShadow                     : theme.dimensions.shadowDialog,
    borderRadius                  : theme.dimensions.borderRadiusPanel,
    backgroundColor               : theme.palette.background.paper,
    minWidth                      : '160px',
    outline                       : 0,
    opacity                       : 0,
    pointerEvents                 : 'none',
    transition                    : theme.transitions.create('opacity'),
    marginLeft                    : 0,
    marginTop                     : 0,
    fontSize                      : '10px',
    '&.react-contextmenu--visible': {
      opacity      : '1',
      pointerEvents: 'auto',
      zIndex       : '9999',
    },
  },
  listItem: {
    position   : 'relative',
    paddingLeft: '10px',
    '&:hover'  : {
      backgroundColor: theme.palette.action.hover,
    },
  },
  listItemText: {
    fontSize     : theme.typography.size.m,
    fontWeight   : theme.typography.fontWeightMedium,
    letterSpacing: theme.dimensions.letterSpacing,
  },
}));

const useStylesSubHeader = makeStyles(theme => ({
  root: {
    paddingTop          : '8px',
    paddingBottom       : '6px',
    lineHeight          : '1.5',
    paddingLeft         : '10px',
    letterSpacing       : '1px',
    textTransform       : 'uppercase',
    borderBottomWidth   : '1px',
    borderBottomStyle   : 'solid',
    borderBottomColor   : theme.palette.neutral[400],
    fontSize            : theme.typography.size.s,
    color               : theme.palette.text.primary,
    fontWeight          : theme.typography.fontWeightBold,
    backgroundColor     : theme.palette.neutral[200],
    borderTopLeftRadius : theme.dimensions.borderRadiusPanel,
    borderTopRightRadius: theme.dimensions.borderRadiusPanel,
  },
}));

const SubMenuIcon = styled.i.attrs({
  children : 'chevron_right',
  className: 'material-icons-4com',
})`
  &&& {
    position: absolute;
    right: 5px;
    font-size: 1em;
  }
`;

export { ContextMenuTrigger } from 'react-contextmenu';

export let ContextMenuSubheader = props => {
  const classes = useStylesSubHeader();
  return (
    <ListSubheader component="div" classes={{ root: classes.root }} {...props}>
      {props.children}
    </ListSubheader>
  );
};

const List = styled(props => {
  const passingProps = { ...props };
  delete passingProps.maxHeight;
  return <MuiList {...passingProps} />;
}).attrs({ dense: true })`
  &&& {
    padding-top: 0;
    padding-bottom: 0;

    ${props =>
  props.maxHeight &&
      css`
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
      `}
  }
`;
export let ContextMenu = props => {
  const classes = useStyles();
  return (
    <Portal container={document.body}>
      <OriginalContextMenu
        component="ul"
        hideOnLeave={true}
        className={classes.contextMenuContainer}
        {...props}
      >
        <List role="menuitem" component="ul">
          {props.children}
        </List>
      </OriginalContextMenu>
    </Portal>
  );
};

export let ContextMenuItem = props => {
  const classes = useStyles(props);
  const theme = useTheme();
  const iconStyle = {
    fontSize: theme.typography.size.l,
    color   : theme.palette.neutral[800],
    margin  : '0 7px 0 0',
  };
  const label = (
    <MenuItem component="li">
      <ListItem
        button
        dense
        component="li"
        disabled={props.disabled}
        divider={!props.last}
        onClick={props.onClick}
        data-element={props['data-element']}
        className={classes.listItem}
      >
        {!!props.icon && <Icon style={iconStyle}>{props.icon}</Icon>}
        {!!props.additionalIcon && <Icon style={iconStyle}>{props.additionalIcon}</Icon>}
        <ListItemText
          primary={props.primary}
          secondary={props.secondary}
          disableTypography
          className={classes.listItemText}
        />

        {props.children && !props.disabled && <SubMenuIcon />}
      </ListItem>
    </MenuItem>
  );

  if (props.children && !props.disabled) {
    return (
      <SubMenu hoverDelay={150} title={label} className={classes.subMenuContainer}>
        <List component="ul" role="menuitem" maxHeight={props.maxHeight}>
          {props.children}
        </List>
      </SubMenu>
    );
  }

  return label;
};
