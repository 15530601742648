import React from 'react';
import { makeStyles } from '@pcweb/controls';
import {
  CurrencyEuroField,
  CurrencyEuroWithSeperatorField,
  Date_DDMMYYYY_Field,
  Date_DDMMYYYY_HHMMSS_Field,
  Date_YYYYMMDD_Field,
  Date_YYYYMMDD_HHMMSS_Field,
  Decimal1Field,
  Decimal2Field,
  Decimal3Field,
  EMailField,
  NumberField,
  NumericField,
  PhoneNumberField,
  PostCodeField,
  TextInputField,
  TimeHHMMField,
  TimeHHMMSSField,
  ValidatedField,
} from '@pcweb/controls';
import { useTranslate } from '@pcweb/hooks';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

const useStyles = makeStyles(
  () => ({
    input: {
      width    : '100%',
      marginTop: 0,
    },
  }),
  { name: 'ConservationGuideQuestionTextBox' }
);

export const QuestionTextBox = observer((props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const question = props.question;
  const callFlow = props.callflow;
  const activePage = callFlow.activeCallGuide.activePage;

  const translationKeys = {
    0 : 'TextBoxElement_CreateFormatDescription_Freitext__max___0__Zeichen_',
    1 : 'TextBoxElement_CreateFormatDescription_Zahl',
    2 : 'TextBoxElement_CreateFormatDescription_Zahl__1_Nachkommerstelle_',
    3 : 'TextBoxElement_CreateFormatDescription_Zahl__2_Nachkommerstelle_',
    4 : 'TextBoxElement_CreateFormatDescription_Zahl__3_Nachkommerstelle_',
    5 : 'TextBoxElement_CreateFormatDescription_Zeit__hh_mm_',
    6 : 'TextBoxElement_CreateFormatDescription_Zeit__hh_mm_ss_',
    7 : 'TextBoxElement_CreateFormatDescription_Datum__tt_mm_jjjj_',
    8 : 'TextBoxElement_CreateFormatDescription_Datum__tt_mm_jjjj_hh_mm_ss_',
    14: 'TextBoxElement_CreateFormatDescription_Datum__yyyy_mm_dd_',
    15: 'TextBoxElement_CreateFormatDescription_Datum__yyyy_mm_dd_hh_mm_ss_',
    9 : 'Euro',
    10: 'TextBoxElement_CreateFormatDescription_Euro__Tausendertrennzeichen_',
    11: 'Telefonnummer',
    12: 'PLZ',
    13: 'TextBoxElement_CreateFormatDescription_E_Mail_Adresse__RFC_2822_',
    16: 'TextBoxElement_CreateFormatDescription_Ziffern',
    17: 'TextBoxElement_CreateFormatDescription_Regulärer_Ausdruck',
  };

  const helperText =
    question.format === 0
      ? translate(translationKeys[question.format]).replace(
        '{0}',
        question.maxTextLength
      )
      : translate(translationKeys[question.format]);

  const options = {
    ...props,
    placeholder: translate('Antwort'),
    className  : classes.input,
    multiline  : question.maxVisibleLines > 1,
    minRows    : question.maxVisibleLines,
    required   : question.isMandatory,
    value      : question.value || '',
    onChange   : (e, forceError) => {
      runInAction(() => {
        question.isEvaluated = false;
        activePage.isEvaluated = false;
        question.value = e.target.value;

        if (forceError) {
          question.hasError = true;
        } else {
          question.hasError = undefined;
        }

        activePage.evaluate();
      });
    },
    error: question.isEvaluated && question.hasError,
    helperText,
  };

  switch (question.format) {
  case 0:
    return <TextInputField {...options} />;
  case 1:
    return <NumberField {...options} />;
  case 2:
    return <Decimal1Field {...options} />;
  case 3:
    return <Decimal2Field {...options} />;
  case 4:
    return <Decimal3Field {...options} />;
  case 5:
    return <TimeHHMMField {...options} />;
  case 6:
    return <TimeHHMMSSField {...options} />;
  case 7:
    return <Date_DDMMYYYY_Field {...options} />;
  case 8:
    return <Date_DDMMYYYY_HHMMSS_Field {...options} />;
  case 14:
    return <Date_YYYYMMDD_Field {...options} />;
  case 15:
    return <Date_YYYYMMDD_HHMMSS_Field {...options} />;
  case 9:
    return <CurrencyEuroField {...options} />;
  case 10:
    return <CurrencyEuroWithSeperatorField {...options} />;
  case 11:
    return <PhoneNumberField {...options} />;
  case 12:
    return <PostCodeField {...options} />;
  case 13:
    return <EMailField {...options} />;
  case 16:
    return <NumericField {...options} />;
  case 17:
    options['expression'] = new RegExp(`^${question.regularExpression}$`);
    if (question.formatDescription) {
      options['helperText'] = translate(question.formatDescription);
    }
    return <ValidatedField {...options} />;
  default:
    return <TextInputField {...options} />;
  }
});

export default QuestionTextBox;
