import React from 'react';
import ReactTooltip from 'react-tooltip';
import { makeStyles } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    borderRadius   : theme.dimensions.borderRadiusInput,
    color          : `${theme.palette.secondary.contrastText} !important`,
    opacity        : 1,
    padding        : theme.spacing(0.5, 1),
    fontSize       : theme.typography.size.xs,
    '&::after'     : {
      borderTopColor: `${theme.palette.secondary.main} !important`,

    }
  },
}));

const Tooltip = props => {
  const classes = useStyles();
  return (
    <ReactTooltip place={props.place} className={classes.tooltip} effect="solid" /> );
};

export default Tooltip;
