import React from 'react';
import { makeStyles } from '@pcweb/controls';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  links: {
    marginLeft: 20,
  },
}),
{name: 'ConservationGuideQuestionLinks'}
);
export const QuestionLinks = observer(props => {
  const question = props.question;
  const disabled = props.disabled;
  const classes = useStyles();

  return (
    <ul className={classes.links}>
      {question.urls.map((url, i) => (
        <li key={i}>
          {!disabled && <a href={url}>{url}</a>}
          {disabled && <span>{url}</span>}
        </li>
      ))}
    </ul>
  );
});

export default QuestionLinks;
