import React from 'react';
import { Paper, makeStyles } from '@pcweb/controls';

const useStyles = makeStyles(() => ({
  // root: {}
}));

export default function PaperComp(props) {
  const classes = useStyles();
  return (
    <Paper
      {...props}
      elevation={0}
      classes={{ root: classes.root, elevation1: classes.elevation1 }}
    ></Paper>
  );
}
