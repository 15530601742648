import React, { useState } from 'react';
import { makeStyles } from '@pcweb/controls';
import { SearchInputField } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  root: {
    '& label': {
      color: `${theme.palette.text.primary} !important`,
    },
    borderRadius        : 0,
    backgroundColor     : theme.palette.background.paper,
    '& input + fieldset': {
      borderRadius: 0,
      border      : 0,
      transition  : theme.transitions.create('border-color'),
    },
    '& input:hover:enabled + fieldset': {
      border: 0,
    },
    '& input:focus:enabled + fieldset': {
      border: 0,
    },
    '& input:disabled + fieldset': {
      color : theme.palette.text.disabled,
      border: 0,
    },
    '& input': {
      paddingTop   : '8.5px',
      paddingBottom: '8.5px',
    },
    '& fieldset': {
      borderRadius: 0,
      border      : 0,
    }
  },
  container: {
    display          : 'flex',
    alignItems       : 'center',
    fontSize         : theme.typography.size.m,
    color            : theme.palette.text.primary,
    padding          : 0,
    backgroundColor  : theme.palette.neutral[400],
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.neutral[900],
    userSelect       : 'none',
    '& .hiddenLabel' : {
      opacity: 0,
      height : 0,
      width  : 0,
    },
    '& button': {
      marginLeft: 3,
    },
    '& > div': {
      marginTop   : 0,
      marginBottom: 0,
    },
  },
}));

/**
 * The quick search bar enables users to filter the displayed list of users in the user monitor by name or phone number
 * (or SIP address for VoIP users) by typing in a search term.
 *
 */
export const QuickSearch = props => {
  const { setQuickSearch, name } = props;
  const [localSearchValue, setLocalSearchValue] = useState(props.quickSearch);
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <label htmlFor="user-quick-search" className="hiddenLabel">
        {name}
      </label>
      <SearchInputField
        classes={{ root: classes.root }}
        id="user-quick-search"
        name={name}
        onKeyDown={event => {
          if (event.key === 'Escape') {
            setQuickSearch('');
            event.target.blur();
          }
        }}
        onChange={event => {
          setLocalSearchValue(event.target?.value || '');
          setQuickSearch(event.target?.value || '');
        }}
        value={localSearchValue}
        fullWidth
      />
    </div>
  );
};

export default QuickSearch;
