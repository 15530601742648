import React from 'react';
import { makeStyles, Button } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  root: {
    height       : theme.dimensions.inputHeight,
    letterSpacing: theme.dimensions.letterSpacing,
    borderRadius : theme.dimensions.borderRadiusInput,
    '&:hover'    : {
      backgroundColor: theme.palette.secondary.main,
    },
    '& i': {
      marginRight: '5px',
      color      : theme.palette.common.white,
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[200] + '!important',
    color          : theme.palette.text.disabled  + '!important',
    '& i'          : {
      color: theme.palette.text.disabled  + '!important',
    },
  },
}));

const PrimaryButton = props => {
  const classes = useStyles();
  return (
    <Button classes={{ root: classes.root, disabled: classes.disabled }} variant="contained" color="primary" disableElevation {...props}>
      {props.children}
    </Button>
  );
};

export default PrimaryButton;
