import React from 'react';
import { SelectComponentOld } from '../../../../controls/Package';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

export const QuestionDropDown = observer(props => {
  const question = props.question;
  const callFlow = props.callflow;
  const activePage = callFlow.activeCallGuide.activePage;

  return (
    <SelectComponentOld
      isSearchable={false}
      visible={true}
      error={question.isEvaluated && question.hasError}
      options={question.options}
      selected={(question.selected && question.selected.id) || ''}
      onChange={e => {
        if (e.value) {
          runInAction(() => question.selected = question.options.find(option => option.id === e.value));
        }
        activePage.evaluate();
      }}
      valueName="id"
      displayName="label"
      disabled={props.disabled}
    />
  );
});

export default QuestionDropDown;
