import { DialogActions as MuiDialogActions, withStyles } from '@pcweb/controls';

export const DialogActions = withStyles(muiTheme => ({
  root: {
    margin                 : 0,
    padding                : muiTheme.spacing(2),
    '& button:nth-child(2)': {
      marginLeft: '8px !important',
    },
  },
}))(MuiDialogActions);
