import React from 'react';
import { makeStyles, DialogContent as MuiDialogContent } from '@pcweb/controls';

const useStyles = makeStyles((muiTheme) => ({
  root: {
    fontSize: muiTheme.typography.size.m,
    padding : muiTheme.spacing(3)
  },
}));

export function DialogContent(props) {
  const classes = useStyles();
  return (
    <MuiDialogContent className={classes.root} {...props}>
      {props.children}
    </MuiDialogContent>
  );
}
