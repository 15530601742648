import React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@pcweb/controls';

const useStylesTable = makeStyles({
  root: {
    borderCollapse       : 'collapse',
    '& tr:first-child th': {
      borderTop: 0,
    },
    '& tr:lst-child td': {
      borderBottom: 0,
    },
    '& tr td:first-child': {
      borderLeft: 0,
    },
    '& tr th:first-child': {
      borderLeft: 0,
    },
    '& tr td:last-child': {
      borderRight: 0,
    },
    '& tr th:last-child': {
      borderRight: 0,
    },
  },
});

export function TableComp(props) {
  const classes = useStylesTable();
  return (
    <Table size="small" classes={{ root: classes.root }} {...props}>
      {props.children}
    </Table>
  );
}

const useStylesTableBody = makeStyles(() => ({
  root: {},
}));

export function TableBodyComp(props) {
  const classes = useStylesTableBody();
  return (
    <TableBody classes={{ root: classes.root }} {...props}>
      {props.children}
    </TableBody>
  );
}

const useStylesTableCell = makeStyles(muiTheme => ({
  root: {
    fontSize  : muiTheme.typography.size.s,
    whiteSpace: 'nowrap',
    '& > i'   : {
      verticalAlign: 'middle',
      color        : muiTheme.palette.text.secondary,
      '& .green'   : {
        color: muiTheme.palette.utility.green.main,
      },
      '& .red': {
        color: muiTheme.palette.utility.red.main,
      },
    },
    '& span': {
      '& > i': {
        verticalAlign: 'middle',
      },
    },
  },
  head: {
    color          : muiTheme.palette.text.primary,
    backgroundColor: muiTheme.palette.neutral[200],
    position       : 'sticky',
    top            : 0,
    zIndex         : '10',
    fontWeight     : '500',
    textOverflow   : 'ellipsis',
    whiteSpace     : 'nowrap',
  },
  sizeSmall: {
    padding       : '0 5px',
    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

export function TableCellComp(props) {
  const classes = useStylesTableCell();
  return (
    <TableCell
      classes={{
        root     : classes.root,
        head     : classes.head,
        sizeSmall: classes.sizeSmall,
      }}
      {...props}
    >
      {props.children}
    </TableCell>
  );
}

const useStylesTableHead = makeStyles(muiTheme => ({
  root: {
    backgroundColor: muiTheme.palette.neutral[200],
  },
}));

export function TableHeadComp(props) {
  const classes = useStylesTableHead();
  return (
    <TableHead classes={{ root: classes.root }} {...props}>
      {props.children}
    </TableHead>
  );
}

const useStylesTableRow = makeStyles(muiTheme => ({
  root: {
    height             : muiTheme.dimensions.tableRowHeight,
    '&:nth-child(even)': {
      backgroundColor: muiTheme.palette.neutral[50],
    },
    '&:hover': {
      backgroundColor: muiTheme.palette.action.hover
    },
    '&:focus': {
      backgroundColor: muiTheme.palette.action.selected
    }
  },
}));

export function TableRowComp(props) {
  const classes = useStylesTableRow();
  return (
    <TableRow classes={{ root: classes.root }} {...props}>
      {props.children}
    </TableRow>
  );
}
