import React from 'react';
import { useTheme, makeStyles, Dialog as MuiDialog, alpha } from '@pcweb/controls';

const useStyles = makeStyles(theme => ({
  root: {
    transition    : theme.transitions.create('all'),
    background    : alpha(theme.palette.secondary.light, 0.4),
    backdropFilter: 'blur(2px)',
    '& > div'     : {
      backgroundColor: 'transparent',
    },
  },
}));

const DialogComponent = props => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <MuiDialog
      maxWidth={false}
      {...props}
      classes={{ root: classes.root }}
      PaperProps={{
        style: {
          boxShadow: theme.dimensions.shadowDialog,
        },
      }}
    >
      {props.children}
    </MuiDialog>
  );
};

export default DialogComponent;
