import React from 'react';

export const SdkContext = React.createContext({});

export const addSdkConsumer = Component => {
  class Consumer extends React.Component {
    render() {
      return (
        <SdkContext.Consumer>{sdk => <Component sdk={sdk} {...this.props} />}</SdkContext.Consumer>
      );
    }
  }
  return Consumer;
};

export const addSdkProvider = Component => {
  class Provider extends React.Component {
    state = {
      sdk: {},
    };

    render() {
      const { sdk } = this.state;
      return (
        <SdkContext.Provider value={sdk}>
          <Component {...this.props} />
        </SdkContext.Provider>
      );
    }
  }
  return Provider;
};
