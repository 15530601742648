import React from 'react';
import { Icon, IconButtonComponent } from '../../Package';
import {
  withStyles,
  DialogTitle as MuiDialogTitle
} from '@pcweb/controls';

const styles = (muiTheme) => ({
  root: {
    margin         : 0,
    padding        : muiTheme.spacing(2, 3),
    color          : muiTheme.palette.text.primary,
    backgroundColor: muiTheme.palette.neutral[100],
  },
  closeButton: {
    position: 'absolute !important',
    right   : muiTheme.spacing(2),
    top     : muiTheme.spacing(1),
  },
});

export const TitleText = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {children}
      {onClose ? (
        <IconButtonComponent
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButtonComponent>
      ) : null}
    </MuiDialogTitle>
  );
});
